import React from "react";
import styled from 'styled-components';
import HoverImg from "../../assets/cardOpen1.png";
import BgImg from "../../assets/cardBlockchain.png";

const CardContainer = styled.div`
  position: relative;
  width: 30vh;
  height: 30vh;
  background-image: url(${props => props.bgImage});
  background-size: cover;
  background-position: center;
  background-color: bisque;
  padding: 20px;
  transition: background-image 0.3s ease;

  &:hover {
    transform: scale(1.1); /* Zoom sur la carte */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Augmente l'ombre */
    background-color: rgba(255, 255, 255, 0.8); /* Change la couleur de fond */
  }
`;

const CardComponent = ({ bgImage, hoverImage, children, onClick }) => {
    return (
        <CardContainer
            bgImage={BgImg}
            hoverImage={HoverImg}
            onClick={onClick}
        >
        </CardContainer>
    );
};

export default CardComponent;
