import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CardComponent from '../Card/Card';

const CardContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
`;

const CardContent = styled.div`
  display: ${props => props.isVisible ? 'block' : 'none'};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  transition: width 0.5s, height 0.5s;
  width: 50vh;

  @media (max-width: 768px) {
    width: 80vw; /* Ajuster la largeur pour les appareils mobiles */
    height: 80vw; /* Ajuster la hauteur pour les appareils mobiles */
    top: 30%;
  }
`;

const FrameGrid = ({ CardsData }) => {
    const [visibleCard, setVisibleCard] = useState(null);

    const toggleContentVisibility = (id) => {
        setVisibleCard(id === visibleCard ? null : id);
    };

    const closeCardContent = () => {
        setVisibleCard(null);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.card-container') && visibleCard !== null) {
                closeCardContent();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [visibleCard]);

    const cards = CardsData.map(card => (
        <div key={card.id} className="card-container">
            <CardComponent
                id={card.id}
                bgImage={card.bgImage}
                hoverImage={card.hoverImage}
                onClick={() => toggleContentVisibility(card.id)}
            >
                {card.name}
            </CardComponent>
        </div>
    ));

    return (
        <div style={{padding:"10px 0"}}>
            <CardContainer>{cards}</CardContainer>
            <CardContent isVisible={visibleCard !== null}>
                <img src={CardsData.find(card => card.id === visibleCard)?.hoverImage} alt="Image" style={{ width: "100%" }} />
            </CardContent>
        </div>
    );
};

export default FrameGrid;

