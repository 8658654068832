import logo from './logo.svg';
import './App.css';
import BGImg from "./assets/cardBlockchain.png";
import HoverImg from "./assets/V2.jpg";
import FrameGrid from './Component/Frame/Frame';
import Logo from './assets/Logo HAAS 2022.svg'

const CardsData = Array.from({ length: 32 }, (_, index) => ({
  id: index + 1,
  bgImage: BGImg,
  hoverImage: HoverImg,
  name: "blockchaine"
}));

function App() {
  return (
    <div className="App" style={{background:"#e30613"}}>
      <header className="App-header">
        <div style={{padding:'20px', backgroundColor:'white'}}>
          <img src={Logo} alt='Logo haas avocats' />
        </div>
        <h4>Présentoire des services du cabinets</h4>
      </header>
      <h2 style={{color:"white"}}>Notre liste</h2>
      <FrameGrid CardsData={CardsData} />
    </div>
  );
}

export default App;
